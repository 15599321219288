<template>
  <div
    class="auth-wrapper auth-v2"
    style="background-color: #fff;"
  >
    <b-row class="auth-inner m-0">
      <b-col lg="4" />
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <!--<span class="d-flex align-items-center justify-content-center mb-2">
            <b-img
              style="width: 30%;    padding-bottom: 5%;"
              :src="governo"
              alt="logo"
            />
          </span>-->
          <span class="d-flex align-items-center justify-content-center">
            <b-img
              style="width: 50%;"
              :src="prosaiParintins"
              alt="logo"
            />
          </span>
          <!-- form -->
          <validation-observer
            ref="loginForm"
            v-slot="{ handleSubmit }"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="handleSubmit(login)"
            >
              <!-- email -->
              <validation-provider
                #default="{ errors, classes }"
                name="Matricula"
                vid="rcode"
                rules="required"
              >
                <b-form-group
                  label="Matrícula"
                  label-for="rcode"
                >
                  <b-form-input
                    id="rcode"
                    v-model="rcode"
                    :class="classes"
                    :name="rcode"
                    placeholder="0000"
                  />
                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                </b-form-group>
              </validation-provider>
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="password">Senha</label>
                </div>
                <validation-provider
                  #default="{ errors, classes }"
                  name="Senha"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :class="classes"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                      placeholder="******"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :class="classes"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Lembrar-me
                </b-form-checkbox>
              </b-form-group>
              <b-button
                type="submit"
                variant="primary"
                style="background-color:#232f86 !important;"
                block
              >
                <b-spinner
                  v-if="load"
                  small
                />
                <span v-if="load"> Logando...</span>
                <span v-if="!load">ENTRAR</span>
              </b-button>
            </b-form>
          </validation-observer>
          <span class="d-flex align-items-center justify-content-center mt-4">
            <b-img
              style="width: 27%;margin-right: 5%;"
              :src="cosama"
              alt="logo"
            />
            <b-img
              style="width: 15%; margin-right: 5%;"
              :src="ugpe"
              alt="logo"
            />
            <b-img
              style="width: 18%; margin-right: 5%;"
              :src="sedurb"
              alt="logo"
            />
            <b-img
              style="width: 25%;"
              :src="governo"
              alt="logo"
            />
          </span>
          <!--<b-card-text class="text-center mt-2">
              <span>{{ $t("Don't have an account?") }}</span>
              <b-link :to="{name:'auth-register'}">
                <span>&nbsp;{{ $t('Create an account') }}</span>
              </b-link>
            </b-card-text>-->
        </b-col>
      </b-col>
      <b-col lg="4" />
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */

/* eslint-disable */
  
  
  import {
    BRow, BCol, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, 
    BFormCheckbox, BImg, BForm, BButton, VBTooltip, BCarousel
  } from 'bootstrap-vue'
  
  import { required, email } from '@validations'
  import { ValidationProvider, ValidationObserver, configure, localize, } from 'vee-validate'
  import pt from 'vee-validate/dist/locale/pt_BR.json'
  
  import useJwt from '@/auth/jwt/useJwt'
  import { togglePasswordVisibility } from '@core/mixins/ui/forms'
  import store from '@/store/index'
  import { getHomeRouteForLoggedInUser } from '@/auth/utils'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import ability from '@/libs/acl/ability'
  
  configure({
    generateMessage: localize({
      pt,
    }),
  })
  
  export default {
    directives: {
      'b-tooltip': VBTooltip,
    },
    components: {
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BInputGroupAppend,
      BInputGroup,
      BFormCheckbox,
      BImg,
      BForm,
      BButton,
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
      return {
        status: '',
        password: '',
        rcode: '',
        load: false,
        sideImg: require('@/assets/images/pages/login-v2.svg'),
        prosai: require('@/assets/images/logo/prosai.png'),
        parintins: require('@/assets/images/logo/parintins.png'),
        cosama: require('@/assets/images/logo/cosama.png'),
        ugpe: require('@/assets/images/logo/ugpe.png'),
        sedurb: require('@/assets/images/logo/sedurb.png'),
        governo: require('@/assets/images/logo/governo.png'),
        prosaiParintins: require('@/assets/images/logo/prosai_parintins.png'),
  
        // validation rules
        required,
        email,
      }
    },
    computed: {
      passwordToggleIcon() {
        return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      },
      imgUrl() {
        if (store.state.appConfig.layout.skin === 'dark') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
          return this.sideImg
        }
        return this.sideImg
      },
    },
    methods: {
      login() {
        this.load = true
        this.$refs.loginForm.validate().then(success => {
          if (success) {
            useJwt.login({
              r_code: this.rcode,
              password: this.password,
            }).then(resp => {
  
              if (resp.data.data.success) {
            
                const { userData } = resp.data.data
                const verify = userData.ability.find((item) => item.subject === "prosai-external")
                if(verify) {
                  useJwt.setToken(resp.data.data.accessToken)
                  localStorage.setItem('userData', JSON.stringify(userData))
                  ability.update(userData.ability)
    
                  this.load = false
                  this.$router.replace({ name: 'prosai-painel' })
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `${userData.name || userData.username}`,
                        icon: 'CheckCircleIcon',
                        variant: 'success',
                        text: `Login realizado com sucesso!.`,
                      },
                    })
                  })
                } else {
                  this.load = false
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Login',
                      icon: 'AlertTriangleIcon',
                      variant: 'warning',
                      text: 'Usuário não tem permissão de acesso!',
                    },
                  })
                }
              } else {
                this.load = false
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Login',
                    icon: 'AlertTriangleIcon',
                    variant: 'warning',
                    text: resp.data.data.message,
                  },
                })
              }
  
            }).catch(error => {
              this.load = false
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Falha',
                  icon: 'XIcon',
                  variant: 'danger',
                  text: error.message,
                },
              })
            })
          }
        })
      },
    },
  }
</script>
  
<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>
  